<style >
</style>
<template>
    <f7-page
        @page:beforein="pageBeforeIn" @page:afterin="pageAfterIn"
        :page-content="false"
        v-bind:style="{'background-color': '#efefef !important'}">
        <nav-bar></nav-bar>
        <f7-page-content>
                <f7-block class="main">
                    <f7-block class="box-score" >
                        <f7-row class="justify-content-center align-items-center">
                            <f7-col medium="50"
                                style="background-color: #fff;"
                                width="100"
                                tablet-width="50"
                                desktop-width="40">
                                <f7-block class="p-p-0-i elevation-8" >
                                    
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #fff; text-transform: uppercase; background: #ff9500; padding: 15px; margin: 0px !important; text-align: center;">RESULT</h2>
                                    <div style="margin:20px">
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Mã tra cứu:  <span style='color:#ff9500'>{{this.userQuiz.id}} </span>
                                    </h2>
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Tên bài kiểm tra:  <span style='color:#ff9500'>{{this.quiz.quizName}}</span>
                                    </h2>
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Người kiểm tra:  <span style='color:#ff9500'>{{this.userInfo.ten}}</span>
                                    </h2>
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important;">
                                        Thời gian bắt đầu:  <span style='color:#ff9500'>{{formatDate(this.userQuiz.timeBeginQuiz)}} </span>
                                    </h2>
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Thời gian nộp bài:  <span style='color:#ff9500'>{{formatDate(this.userQuiz.timeEndQuiz)}} </span>
                                    </h2>
                                 
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Số câu đúng:  <span style='color:#ff9500'>{{correctQuestions}}/{{totalQuestions}} câu </span>
                                    </h2>
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Điểm đạt được:  <span style='color:#ff9500'>{{Math.floor(correctQuestions/totalQuestions * 100)}}/100 điểm</span>
                                    </h2>
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Điểm yêu cầu:  <span style='color:#ff9500'>{{passGrade}}/100 điểm</span>
                                    </h2>
                                    <h2
                                        class="ptitle-heading-1 fs-roboto-light"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; ">
                                        Kết quả:  
                                    </h2>
                                    <h1
                                     class="ptitle-heading-1 fs-roboto-bold"
                                        style="color: #2196f3; padding: 5px; margin: 0px !important; text-align: center;">
                                        <span v-html='resultUserQuiz'></span>
                                    </h1>
                                    </div>
                                    <f7-block style="padding: 15px !important;">
                                        <f7-row class="justify-content-center">
                                            <f7-col width="50" tablet-width="30" desktop-width="30">
                                                <f7-button
                                                    class="height-bt w-100 fs-roboto-medium"
                                                    style="background: #ff9500;"
                                                    fill
                                                    v-bind:href="'/result-detail-quiz/'+ this.$f7route.params.userquizId + '/'"
                                                    >Xem Đáp Án</f7-button>
                                            </f7-col>
                                        </f7-row>
                                    </f7-block>
                                    <f7-block style="padding: 15px !important;">
                                        <f7-row class="justify-content-center">
                                            <f7-col width="50" tablet-width="30" desktop-width="30">
                                                <f7-button
                                                    class="height-bt w-100 fs-roboto-medium"
                                                    style="background: #28a745;"
                                                    fill
                                                    href="/list-quiz/">Về Trang Chủ</f7-button>
                                            </f7-col>
                                        </f7-row>
                                    </f7-block>
                                </f7-block>
                            </f7-col>
                        </f7-row>
                    </f7-block>
                </f7-block>
         </f7-page-content>
    </f7-page>
</template>

<script>
    import axios from "axios";
    import apiEndpoint from "../../apiEndpoint.js"
    import NavBar from './NavBar.vue';
    var dateFns = require("date-fns");
    export default {
        components : {
            NavBar,
        },
        data() {
            return {
                totalQuestions: 0,
                correctQuestions: 0,
                apiUrl: apiEndpoint + "api/UserQuizzes",
                dataFromAPI: [],
                userQuiz: {},
                passGrade: null,
                resultUserQuiz: '',
                userInfo: {},
                quiz: {},
            };
        },
        methods : {
            toHHMMSS(sec_num) {
                // var sec_num = parseInt(second, 10); // don't forget the second param
                var hours   = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                if (hours   < 10) {hours   = "0"+hours;}
                if (minutes < 10) {minutes = "0"+minutes;}
                if (seconds < 10) {seconds = "0"+seconds;}
                return hours+':'+minutes+':'+seconds;
            },
            getThoiGianThi(myUserQuiz){
                let time = new Date(myUserQuiz.timeEndQuiz) - new Date(myUserQuiz.timeBeginQuiz);
                console.log('viet',time);
                time = this.toHHMMSS(time/1000);
                return time;
            },
            formatDate(date){
                if(!date) return;
                return dateFns.format(new Date(date),'dd/MM/yyyy HH:mm:ss')
            },
            async getResultUserQuiz(){
                this.passGrade = await this.getpassGrade(this.userQuiz);
                if(Math.floor(this.correctQuestions/this.totalQuestions *100)>=this.passGrade){
                    this.resultUserQuiz = '<span style="color: #28a745 ">PASS</span>';
                }else{
                    this.resultUserQuiz = '<span style="color: #e91e63 ">FAIL</span>';
                }
            },
            async getpassGrade(userQuiz){
                let passGrade;
                 let filter = {
                    where: {
                        id: userQuiz.quizId
                    }
                };
                try {
                    let data = await axios.get(apiEndpoint + "api/Quizzes", {
                        params: {
                            filter: filter
                        }
                    });
                    this.quiz = data.data[0];
                    console.log('viet this.quiz',this.quiz);
                    passGrade = this.quiz.passGrade;
                } catch (error) {
                    console.log(error);
                }
                return passGrade;

            },
            pageBeforeIn: function (e, page) {
                const app = this.$f7;
                app
                    .preloader
                    .hide();
            },
            pageAfterIn: function (e, page) {
                const app = this.$f7;
                app
                    .preloader
                    .hide();
            },
            onPageBeforeRemove() {
                const self = this;
                if (self.popup)
                    self.popup.destroy();
                }
            ,
            async getUserQuiz() {
                // axios.defaults.headers= {     Authorization : this.accessToken };
                let filter = {
                    where: {
                        id: this.$f7route.params.userquizId
                    }
                };
                try {
                    let data = await axios.get(this.apiUrl, {
                        params: {
                            filter: filter
                        }
                    });
                    this.dataFromAPI = data.data;
                    this.userQuiz = this.dataFromAPI[0];
                    this.totalQuestions = this.userQuiz.listQuestions.length;
                    this.correctQuestions = this.userQuiz.correctQuestions;

                } catch (error) {
                    console.log(error);
                }

            },
            async sendEmail(){
                let urlSendMail = apiEndpoint+ "api/ContactLists/sendEmail2";
                let emailItem = {
                        to: this.userInfo.email,
                        from: 'exam.eic.com.vn <admin@eic.com.vn>',
                        subject: 'YOUR QUIZ RESULTS (mã tra cứu : '+this.userQuiz.id+')',
                        text : `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
      <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta name="x-apple-disable-message-reformatting">
        <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
        <title></title>
        
          <style type="text/css">
            a { color: #0000ee; text-decoration: underline; }
      @media only screen and (min-width: 620px) {
        .u-row {
          width: 600px !important;
        }
        .u-row .u-col {
          vertical-align: top;
        }
      
        .u-row .u-col-100 {
          width: 600px !important;
        }
      
      }
      
      @media (max-width: 620px) {
        .u-row-container {
          max-width: 100% !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        .u-row .u-col {
          min-width: 320px !important;
          max-width: 100% !important;
          display: block !important;
        }
        .u-row {
          width: calc(100% - 40px) !important;
        }
        .u-col {
          width: 100% !important;
        }
        .u-col > div {
          margin: 0 auto;
        }
      }
      body {
        margin: 0;
        padding: 0;
      }
      
      table,
      tr,
      td {
        vertical-align: top;
        border-collapse: collapse;
      }
      
      p {
        margin: 0;
      }
      
      .ie-container table,
      .mso-container table {
        table-layout: fixed;
      }
      
      * {
        line-height: inherit;
      }
      
      a[x-apple-data-detectors='true'] {
        color: inherit !important;
        text-decoration: none !important;
      }
      
      </style>
        
        
      
      <!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Raleway:400,700" rel="stylesheet" type="text/css"><!--<![endif]-->
      
      </head>
      
      <body class="clean-body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ffffff;>
        <!--[if IE]><div class="ie-container"><![endif]-->
        <!--[if mso]><div class="mso-container"><![endif]-->
        <table style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #ffffff;width:100%" cellpadding="0" cellspacing="0">
        <tbody>
        <tr style="vertical-align: top">
          <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
          <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #ffffff;"><![endif]-->
          
      
      <div class="u-row-container" style="padding: 0px 10px;background-color: rgba(255,255,255,0)">
        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #fff;border-width:5px;border-style:double;border-color:#E39717">
          <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px 10px;background-color: rgba(255,255,255,0);" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #fff;"><![endif]-->
            
      <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
      <div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
        <div style="width: 100% !important;">
        <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
        
      <table style="font-family:'Raleway',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
        <tbody>
          <tr>
            <td style="overflow-wrap:break-word;word-break:break-word;padding:20px;font-family:'Raleway',sans-serif;" align="left">
              
      <table width="100%" cellpadding="0" cellspacing="0" border="0">
        <tr>
          <td style="padding-right: 0px;padding-left: 0px;" align="center">
            
            <img align="center" border="0" src="https://eic.com.vn/wp-content/uploads/2021/11/logo_eic_transparent.png" alt="Image" title="Image" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 227px;" width="227"/>
            
          </td>
        </tr>
      </table>
      
            </td>
          </tr>
        </tbody>
      </table>
      
      <table style="font-family:'Raleway',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
        <tbody>
          <tr>
            <td style="overflow-wrap:break-word;word-break:break-word;padding:16px 20px 8px;font-family:'Raleway',sans-serif;" align="left">
              
        <div style="color: #E39717; line-height: 120%; text-align: center; word-wrap: break-word;">
          <p style="font-size: 14px; line-height: 120%;"><strong><span style="font-size: 25px; line-height: 57.6px; font-family: Raleway, sans-serif;">QUIZ RESULTS</span></strong></p>
        </div>
      
            </td>
          </tr>
        </tbody>
      </table>
      
      <table style="font-family:'Raleway',sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
        <tbody>
          <tr>
            <td style="overflow-wrap:break-word;word-break:break-word;padding:15px 20px 14px;font-family:'Raleway',sans-serif;" align="left">
              
        <div style="color: #093AA5; line-height: 120%; text-align: left; word-wrap: break-word;">
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Mã tra cứu: <span style='color:#E39717'>${this.userQuiz.id}</span></span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Tên bài kiểm tra: <span style='color:#E39717'>${this.quiz.quizName}</span></span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Người kiểm tra: <span style='color:#E39717'>${this.userInfo.ten}</span></span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Thời gian bắt đầu: <span style='color:#E39717'>${this.formatDate(this.userQuiz.timeBeginQuiz)}</span></span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Thời gian nộp bài: <span style='color:#E39717'>${this.formatDate(this.userQuiz.timeEndQuiz)}</span></span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Số câu đúng: <span style='color:#E39717'>${this.correctQuestions}/${this.totalQuestions} câu</span></span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Điểm đạt được: <span style='color:#E39717'>${Math.floor(this.correctQuestions/this.totalQuestions * 100)}/100 điểm</span></span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 20px;">Điểm yêu cầu: <span style='color:#E39717'>${this.passGrade}/100 điểm</span> </span></p>
          <p style="font-size: 18px; line-height: 120%;"><span style="font-size: 18px; line-height: 50px;">Kết quả:  <span style='font-size:25px'>${this.resultUserQuiz}</span> </span></p>
        </div>
      
            </td>
          </tr>
        </tbody>
      </table>
      
        <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
        </div>
      </div>
      <!--[if (mso)|(IE)]></td><![endif]-->
            <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
          </div>
        </div>
      </div>
      
      
      
      <div class="u-row-container" style="padding: 0px 10px;background-color: rgba(255,255,255,0)">
        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
          <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px 10px;background-color: rgba(255,255,255,0);" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->
            
      <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
      <div class="u-col u-col-100" style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
        <div style="width: 100% !important;">
        <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
        
      
        <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
        </div>
      </div>
      <!--[if (mso)|(IE)]></td><![endif]-->
            <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
          </div>
        </div>
      </div>
      
      
          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
          </td>
        </tr>
        </tbody>
        </table>
        <!--[if mso]></div><![endif]-->
        <!--[if IE]></div><![endif]-->
      </body>
      
      </html>
      `
                    }
                console.log('viet emailItem',emailItem);
                try{
                    axios.post(urlSendMail,emailItem).then(res => console.log(res.status));
                }catch(err){
                    alert(err);
                }
            },
        },
       
        async created() {
            this.userInfo = JSON.parse(localStorage.getItem('eic_quiz_userInfo'));
            await this.getUserQuiz();
            await this.getResultUserQuiz();
            await this.sendEmail();

        }
    };
</script>
