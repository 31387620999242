<style>
.dg-btn--ok, .dg-btn--cancel{
    max-width: 106px !important;
}
</style>
<style scoped>
    .click-event-dialog{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99;
        top: 0;
        cursor: pointer;
    }
    .p-p-50{
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .style-chu-thich{
        padding: 20px 50px;
        background-color: #ccc;
    }
    .style-line-time{
        padding-top: 0px;
    }
    h2{
        margin-top: 20px;
    }
    @media (max-width: 767px) {
        .p-p-50{
            padding: 15px;
        }
        .style-chu-thich{
            padding: 15px 15px;
        }
    }
</style>

<template>
    <f7-page
        @page:beforein="pageBeforeIn"
        @page:afterin="pageAfterIn"
        class="style-bg-page"
        :page-content="false"
        v-bind:style="{'background-color': '#F0F1F5 !important'}">
        <nav-bar></nav-bar>
        <f7-page-content class="">
            <f7-block class="style-bg-page">
                <f7-navbar class="style-back-link" back-link="Back"></f7-navbar>
                <f7-block class="main ps_what--container style-bg-page">
                    <f7-row class="align-items-center">
                        <f7-list
                            form
                            class="style-form p-mb-0-i w-100 elevation-2 p-p-50"
                            style="background-color: #fff; max-width: 768px; margin: 0px auto !important;">

                            <f7-row>
                                <f7-col width="100" desktop-width="100">
                                    <f7-block>
                                        <f7-row class="align-items-center style-line-time">
                                            <f7-col width="70" >
                                                <h2 style="color: #27515C; " class="fs-roboto-bold">
                                                     Câu {{currentIndex + 1}}
                                                </h2>
                                            </f7-col>
                                            <f7-col width="30">
                                                <f7-button
                                                    style="background-color: #3BA94B; font-size: 12px;"
                                                    v-bind:href='"/result-quiz/"+this.$f7route.params.userquizId+"/"'
                                                    fill
                                                    class="text-uppercase height-bt fs-roboto-medium">Kết Quả</f7-button>
                                            </f7-col>
                                           
                                        </f7-row>

                                        <f7-block
                                            style="color: #27515C; "
                                            class="block-question fs-roboto-medium"
                                            v-html="`${item.description || ''}`"></f7-block>
                                        <f7-list :key="componentKey">
                                            <!-- listAnswer -->
                                            
                                            <f7-list-item
                                                :disabled="true"
                                                style="color: #27515C; "
                                                v-for="(cautraloi, index) in listCauTraLoi"
                                                :title="`${htmlString(cautraloi.content)}`"
                                                :key="index"
                                                class="tag-group-list fs-roboto-light"
                                                :value="index"
                                                name="demo-radio"
                                                :checkbox="isCheckboxType(item)"
                                                :radio="isRadioType(item)"
                                                :checked="isAnswerChecked(index)"
                                                @change="onChange(index, cautraloi, $event)">
                                                
                                                    <div v-bind:class="statusAnswer(cautraloi, index)" v-if="showDivResult(cautraloi)">
                                                    <f7-badge color="green">{{cautraloi.isCorrect?'':'Sai'}}</f7-badge>
                                                    </div>
                                                </f7-list-item>
                                        </f7-list>
                                    </f7-block>
                                    <f7-block>
                                        <f7-row class="align-items-center">
                                            <f7-col width="20" tablet-width="20">
                                                    <f7-button
                                                        style="background-color: #E7643B"
                                                        fill
                                                        class="style-bt-prev  height-bt"
                                                        v-bind:class="statusPrev()"
                                                        v-on:click="prevQuestion()">
                                                        <f7-icon ios="material:keyboard_arrow_left" md="material:keyboard_arrow_left"></f7-icon>
                                                    </f7-button>
                                                </f7-col>
                                            <f7-col width="60" tablet-width="60" class="style-box-ch">
                                                <!-- button answer -->
                                                <f7-button
                                                    style="background-color: #E7643B; width:80% "
                                                    fill
                                                    class="mg-0-auto height-bt w-100 fs-roboto-medium"
                                                    :disabled="true"
                                                    v-on:click="answerQuestion(item)">TRẢ LỜI</f7-button>
                                            </f7-col>
                                             <f7-col width="20" tablet-width="20">
                                                    <f7-button
                                                        style="background-color: #E7643B"
                                                        fill
                                                        class="style-bt-next height-bt"
                                                        v-bind:class="statusNext()"
                                                        v-on:click="nextQuestion()">
                                                        <f7-icon ios="material:keyboard_arrow_right" md="material:keyboard_arrow_right"></f7-icon>
                                                    </f7-button>
                                                </f7-col>
                                        </f7-row>
                                    </f7-block>
                                    <f7-block>
                                        <f7-block>
                                            <f7-row class="align-items-center">
                                                <f7-col width="100" tablet-width="100" style="display: flex;margin-top: 20px; justify-content: center">
                                                    <div>
                                                        <!-- Pagination -->
                                                        <f7-button
                                                            class="button button-fill no-border-radius style-list-bt fs-roboto-light"
                                                            v-bind:class="statusButton(index)"
                                                            v-for="(item, index) in listQuestion"
                                                            :key="index"
                                                            v-on:click="gotoQuestion(index)">{{index + 1}}</f7-button>
                                                    </div>
                                                </f7-col>
                                            </f7-row>
                                        </f7-block>
                                    </f7-block>
                                </f7-col>
                            </f7-row>
                        </f7-list>
                    </f7-row>
                </f7-block>
            </f7-block>
            <f7-block>
                <f7-block
                    class="main ps_what--container"
                    style="margin-top: 0px !important;">
                    <f7-row
                        class="style-chu-thich"
                        style="max-width: 768px; margin: 0px auto !important;">
                        <!-- <f7-block class="style-box-number">
                            <f7-block>
                                <f7-row>
                                    <f7-col width="100" tablet-width="100" class="style-box-ch p767-order-12">
                                        <div class="d-block">
                                            <f7-button
                                                class="button button-fill no-border-radius style-list-bt"
                                                v-bind:class="statusButton(index)"
                                                v-for="(item, index) in listQuestion"
                                                :key="index"
                                                v-on:click="gotoQuestion(index)">{{index + 1}}</f7-button>
                                        </div>
                                    </f7-col>
                                </f7-row>
                            </f7-block>
                        </f7-block> -->
                        <f7-col width="100" desktop-width="100">
                            <f7-block style="display: flex; justify-content: center; " class="fs-roboto-light">
                                <f7-chip text="Đang xem" style='background-color: #ff9500;color:#fff'  ></f7-chip>&nbsp;
                                <f7-chip text="Câu Đúng"  style='background-color: #28a745;color:#fff' ></f7-chip>&nbsp;
                                <f7-chip text="Câu Sai" style='background-color: #e91e63;color:#fff'></f7-chip>
                            </f7-block>
                        </f7-col>
                    </f7-row>
                </f7-block>
            </f7-block>
        </f7-page-content>
    </f7-page>
</template>

<script>
    import Vue from "vue";
    import NavBar from './NavBar.vue';
    import VueCountdown from "@dmaksimovic/vue-countdown";
    import axios from "axios";
    import apiEndpoint from "../../apiEndpoint.js";

    export default {
        components : {
            NavBar,
            "vue-countdown": VueCountdown
        },
        data() {
            return {
                totalQuestions:0,
                correctQuestions:0,
                userInfo: null,
                componentKey: 0,
                popupOpened: false,
                apiUrl: apiEndpoint + "api/UserQuizzes",
                dataFromAPI: [],
                accessToken: "",
                isBottom: true,
                listQuestion: [],
                item: [],
                listCauTraLoi: [],
                questionNumber: 0,
                currentIndex: 0,
                isActive: false,
                questionStatus: "",
                listAnswers: [],
                answeredIndex: [],
                batdau: "",
                value_radio: "",
                listDapAn: [],
                isCheck: false,
                start: true,
                units: [
                    "minutes", "seconds"
                ],
                message:"Hết giờ",
                popupOpened: false,
                decreasing_pct: 100,
                isCheckForOnchange: true,
                userQuizId: 0,
                timeCount: 0,
                interval: "",
                previousInterval: "",
                cau_truoc_do: 1,
                cau_hien_tai: 0,
                tracking: {
                    userQuizId: 0,
                    quizId: 0,
                    userId: 0,
                    questionTracking: []
                },
                temp: -1,
                mangCheckbox:[],
                chiSo: 0,
                toTal: 0,
                soCauDaTraLoi:0,

            };
        },
     
        beforeRouteEnter(to, from, next) {
          // message takes a while to load
          next(vm => vm.text = 'Text obtained after request ');
        },
        computed: {

        },
        mounted : function () {
            setInterval(() => {
                if (this.is_paused)
                    return;
                this.decreasing_pct = Math.max(this.decreasing_pct - 1, 0);
            }, 600);
        },
        methods : {
            showDivResult(cautraloi){
                if(cautraloi.isCorrect === true){
                    return true;
                }
                return false;

            },
             statusAnswer(cautraloi, index) {
                let statusClass = "";
                if (cautraloi.isCorrect === true) {
                    return statusClass = "bg-color-correct";
                }
                this
                    .item
                    .listAnswers
                    .forEach(val => {
                        if (index === Number(this.item.radioSelected) && cautraloi.isCorrect === false) {
                            statusClass = "bg-color-wrong";
                        }
                    });
                return statusClass;
            },
            htmlString(value) {
                if (value) {
                var tmp = document.createElement("DIV");
                tmp.innerHTML = value;
                var res = tmp.textContent || tmp.innerText || '';
                res.replace('\u200B', ''); // zero width space
                res = res.trim();

                // let n = 70;
                // if (res.length <= n) { return res; }
                // var subString = res.substr(0, n - 1);
                // return (true
                //     ? subString.substr(0, subString.lastIndexOf(' '))
                //     : subString) + "...";
                // }
                return res;
                }
            },
            setAxiosGlobal(token) {
                axios.defaults.headers = { 'Authorization': token };
            },
            forceRerender() {
                this.componentKey += 1;
            },
            pageBeforeIn(e, page) {

                const app = this.$f7;
                //app.preloader.show();
            },
            pageAfterIn(e, page) {
                const app = this.$f7;
                app.preloader.hide();
            },

            openConfirm() {

                const app = this.$f7;
                app.dialog.confirm('<span class="fs-roboto-medium" style="color: #E7643B;font-size: 14px;">Bạn có muốn nộp bài?</span><br/><span class="fs-roboto-light" style="font-size: 14px;">Hãy nhấn nút OK để xác nhận nộp bài.</span>','', async() => {
                    await this.postCauTraLoi();
                    this.$f7router.navigate("/result-quiz/"+this.dataFromAPI.userQuiz.id);
                });
            },
 async getUserQuiz() {
                // axios.defaults.headers= {     Authorization : this.accessToken };
                let filter = {
                    where: {
                        id: this.$f7route.params.userquizId
                    }
                };
                try {
                    let data = await axios.get(this.apiUrl, {
                        params: {
                            filter: filter
                        }
                    });
                    this.dataFromAPI = data.data;
                    this.userQuiz = this.dataFromAPI[0];
                    this.totalQuestions = this.userQuiz.listQuestions.length;
                    this.correctQuestions = this.userQuiz.correctQuestions;

                } catch (error) {
                    console.log(error);
                }

            },
           
            getTotalCorrectQuestions(listQuestions){
                let indexOfCorrectAnswer = (listAnswers) => {
                    let correctIndex;
                    listAnswers.forEach((e,index) => {
                        if(e.isCorrect) {
                            correctIndex = index;
                            return;
                        }
                    })
                    return correctIndex;
                };
                let sum = 0;
                listQuestions.forEach(e => {
                    if(e.questionType === 'ONE' && e.radioSelected === indexOfCorrectAnswer(e.listAnswers)){
                        sum = sum + 1;
                    } else if (e.questionType === 'MANY'){
                        // Cần bổ sung cho trường hợp loại câu hỏi là chọn nhiều đáp án.
                    }
                })
                return sum;
            },
            async postCauTraLoi() {
                let baiThi = {
                    listQuestions: this.listQuestion,
                    id: this.dataFromAPI.userQuiz.id,
                    quizId: Number(this.$f7route.params.quizId) || 0,
                    timeEndQuiz: new Date(),
                    userquizStatus: "COMPLETED",
                    correctQuestions: this.getTotalCorrectQuestions(this.listQuestion),
                };
                this.tracking.quizId = Number(this.$f7route.params.quizId) || 0;
                this.tracking.userQuizId = this.dataFromAPI.userQuiz.id;
                let urlPost = apiEndpoint+ "api/UserQuizzes";
                let listQuestionIds = this
                    .listQuestion
                    .map(val => {
                        return val.id;
                    });
                try {
                    let res = await axios.patch(urlPost, baiThi);
                    let res_tracking = await axios.post(urlTracking, this.tracking);
                } catch (err) {
                }

            },
            onChange(index, cautraloi, $event) {
                this.isCheckForOnchange = false;

                if (this.item.questionType === "MANY"){
                    if ($event.target.checked === true) {
                        this.mangCheckbox.push(index)
                    }
                    else {
                        this.mangCheckbox.splice( this.mangCheckbox.indexOf(index), 1);
                    }
                } else if (this.item.questionType === "ONE") {
                    this.temp = index;
                }
            },

            isAnswerChecked(index) {
                if (this.item.questionType === "ONE" && this.item.radioSelected === index) {
                    return true;


                } else if (this.item.questionType === "MANY" && this.item.checkboxSelected.indexOf(index) > -1) {
                    return true;
                }
                else{ return false;}
            },
            handleTimeExpire() {
                // const elem = this.$refs.btnNopBai.$el; elem.click();
                // this.postCauTraLoi();
                // const elem = this.$refs.btnNopBai.$el;
                // elem.click();
            },
            isDisableAnswerButton() {
                return this.isCheckForOnchange;
            },
            openIndicator() {
                const self = this;
                self
                    .$f7
                    .preloader
                    .hide(); // đổi lại .show()
                setTimeout(() => {
                    self
                        .$f7
                        .preloader
                        .hide();
                }, 500);
            },
            // show loại câu trả lời
            isCheckboxType(items) {
                if (items.questionType === "MANY")
                    return true;
                else
                    return false;
                }
            ,
            isRadioType(items) {
                if (items.questionType === "ONE")
                    return true;
                else
                    return false;
                }
            ,
            statusButton(index) {
                // //Tất cả các nút bắt đầu sẽ là chưa xem
                // let btnClass = "btn-chuaxem";
                // // Vị trí hiện tại sẽ là đang xem
                // if (index === this.currentIndex) {
                //     btnClass = "btn-dangxem"; // xet điều kiện khi goto
                // } else if (this.listQuestion[index].questionStatus === "chua_tra_loi") {
                //     btnClass = "btn-chua_tra_loi";
                // } else if (this.listQuestion[index].questionStatus === "da-chon") {
                //     btnClass = "btn-chon";
                // }
                let btnClass;
                let indexOfCorrectAnswer = (listAnswers) => {
                                    let correctIndex;
                                    listAnswers.forEach((e,index) => {
                                        if(e.isCorrect) {
                                            correctIndex = index;
                                            return;
                                        }
                                    })
                                    return correctIndex;
                                };
                if(index === this.currentIndex){
                     btnClass = "btn-dangxem";
                }else if(this.listQuestion[index].radioSelected === indexOfCorrectAnswer(this.listQuestion[index].listAnswers)){
                    btnClass = "btn-correct";
                }else{
                    btnClass = "btn-wrong"
                }
               
                return btnClass;
            },
            attrs() {
                let attrs = "radio";
                return attrs;
            },
            statusPrev() {
                let btnClass = "btn-hide";
                if (this.currentIndex > 0) {
                    btnClass = "btn-show";
                }
                return btnClass;
            },
            statusNext() {
                let btnClass = "btn-show";
                if (this.currentIndex + 1 > this.listQuestion.length - 1) {
                    btnClass = "btn-hide";
                }
                return btnClass;
            },

            answerQuestion(item) {
                this.isCheckForOnchange = true;
                // chuyen currentIndex sang trantemp ke tiep
                if (item.questionType === "ONE") {
                    if(item.radioSelected === -1) this.soCauDaTraLoi++;
                    item.radioSelected = this.temp;
                }
                else if (item.questionType === "MANY") {
                    if(item.checkboxSelected.length === 0) this.soCauDaTraLoi++;
                    item.checkboxSelected= this.mangCheckbox;
                    this.mangCheckbox= [];

                }
                this.listQuestion[this.currentIndex].questionStatus = "da-chon";
                this.nextQuestion();


                if(this.soCauDaTraLoi>=this.listQuestion.length){
                      this.openConfirm();
                }
                this.forceRerender();

            },
            nextQuestion() {
                this.forceRerender();
                this.isCheckForOnchange = true;
                if (this.currentIndex < this.listQuestion.length - 1) {
                    this.currentIndex++;
                }
                this.gotoQuestion(this.currentIndex);
            },
            prevQuestion() {
                this.forceRerender();
                this.isCheckForOnchange = true;
                if (this.currentIndex > 0) {
                    // this.currentIndex = this.currentIndex - 1; this.item =
                    // this.listQuestion[this.currentIndex]; this.listCauTraLoi =
                    // this.item.listAnswers; this.questionNumber--;
                    // this.listQuestion[this.currentIndex].questionStatus = "CURRENT";
                    // this.listQuestion.forEach((val, i) =>{   if(this.currentIndex != i){
                    // val.questionStatus = '';   } })
                    this.currentIndex--;
                    this.gotoQuestion(this.currentIndex);
                }
            },
            startTime(previousIndex, currentIndex, questionId) {
                //   this.tracking.questionTracking[previousIndex].time = time;
                this.stopTime(this.interval);
                this.interval = setInterval(function () {
                    // this.timeCount++;
                    this.tracking.questionTracking && this.tracking.questionTracking[currentIndex].time++;
                }.bind(this), 1000);
                // this.tracking.questionTracking[currentIndex].time = timeCount;
            },
            stopTime(interval) {
                clearInterval(interval);
            },
            gotoQuestion(index) {
                this.forceRerender();
                this.item = this.listQuestion[index];
                this.listCauTraLoi = this.item.listAnswers;
                if (this.listQuestion[index].questionStatus === "ACTIVE") {
                    this.listQuestion[index].questionStatus = "chua_tra_loi";
                }

                this.startTime(this.cau_truoc_do, index, this.item.id);
                this.stopTime(this.cau_truoc_do);
                this.cau_truoc_do = index;
                this.currentIndex = index;
                let result = 0
                if( this.listQuestion){
                     this.listQuestion.forEach((val_cauhoi, i_cauhoi) => {
                        val_cauhoi.listAnswers.forEach((val_cautraloi, i_cautraloi) => {
                            if(i_cautraloi === Number(val_cauhoi.radioSelected) && val_cautraloi.isCorrect === true){
                                result++;
                                this.chiSo= result;
                                this.toTal = this.listQuestion.length;

                            }
                        });
                    });
                }
            }
        },
        async created() {
            const app = this.$f7;
            //app.preloader.show();
            let token = localStorage.getItem('eic_quiz_tokenKey');
            this.setAxiosGlobal(token);
            this.userInfo =JSON.parse(localStorage.getItem('eic_quiz_userInfo'));
            
            await this.getUserQuiz();
            this.listQuestion = this.userQuiz.listQuestions;
            this.item = this.listQuestion[0];
            this
                .listQuestion
                .forEach(val => {
                    let obj = {
                        id: val.id,
                        time: 0
                    };
                    this
                        .tracking
                        .questionTracking
                        .push(obj);
                });
            this.listCauTraLoi = this.item.listAnswers;


            this.questionNumber = 1;
            if (this.listQuestion[0].questionStatus === "ACTIVE") {
                this.listQuestion[0].questionStatus = "chua_tra_loi";
            }

            app.preloader.hide();
        },
    };
</script>
