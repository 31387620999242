<style scoped>
.block {
  margin: 0;
  padding: 0px 15px;
  color: #8a8a8f;
}
</style>
<template>
  <f7-page
    :page-content="false"
    v-bind:style="{ 'background-color': '#efefef !important' }"
  >
    <nav-bar></nav-bar>
    <f7-page-content class="style-content-page">
      <f7-block
        class="display-flex align-items-center justify-content-center style-postion-block"
      >
        <div class="list no-hairlines" style="width: 100%">
            <div style="width:100%;text-align: center">
            <h2 class="fs-roboto-medium">Đổi Mật Khẩu</h2>
            </div>
          <div class="row">
            <div class="col-100 medium-30" style="margin: auto">
              <ul style="border-radius: 5px">
                <li class="item-content item-input">
                  <div class="item-inner no-margin-left">
                    <div class="item-title item-label fs-roboto-medium">
                      Mật Khẩu Mới
                    </div>
                    <div class="item-input-wrap">
                      <input
                        :type="typeInput1"
                        :value="password"
                        @input="password = $event.target.value"
                        placeholder="Password"
                      />
                      <i
                        v-if="!showPass1"
                        @click="showPassAction1"
                        class="fa fa-eye-slash"
                        style="
                          padding-right: 0px;
                          position: absolute;
                          top: 12px;
                          right: 0px;
                          color: #8c8c8c;
                        "
                        aria-hidden="true"
                      ></i>
                      <i
                        @click="hiddenPassAction1"
                        v-if="showPass1"
                        class="fa fa-eye"
                        style="
                          padding-right: 0px;
                          position: absolute;
                          top: 12px;
                          right: 0px;
                          color: #8c8c8c;
                        "
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </li>
                <li class="item-content item-input">
                  <div class="item-inner no-margin-left">
                    <div class="item-title item-label fs-roboto-medium">
                      Nhập Lại Mật Khẩu Mới
                    </div>
                    <div class="item-input-wrap">
                      <input
                        :type="typeInput2"
                        :value="confirmPassword"
                        @input="confirmPassword = $event.target.value"
                        placeholder="Confirm Password"
                      />
                      <i
                        v-if="!showPass2"
                        @click="showPassAction2"
                        class="fa fa-eye-slash"
                        style="
                          padding-right: 0px;
                          position: absolute;
                          top: 12px;
                          right: 0px;
                          color: #8c8c8c;
                        "
                        aria-hidden="true"
                      ></i>
                      <i
                        @click="hiddenPassAction2"
                        v-if="showPass2"
                        class="fa fa-eye"
                        style="
                          padding-right: 0px;
                          position: absolute;
                          top: 12px;
                          right: 0px;
                          color: #8c8c8c;
                        "
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </li>
              </ul>
              <f7-button
                medium
                raised
                fill
                style="
                  margin-top: 15px;
                  height: 40px;
                  line-height: 40px;
                  background: darkred;
                "
              >
                <f7-link
                  class="fs-roboto-bold text-color-white"
                  style="width: 100%"
                  @click="updatePassword()"
                  >CẬP NHẬT</f7-link
                >
              </f7-button>
            </div>
          </div>
        </div>
      </f7-block>
    </f7-page-content>
  </f7-page>
</template>
<script>
import Vue from "vue";
import NavBar from "./NavBar.vue";
import axios from "axios";
import apiEndpoint from "../../apiEndpoint.js";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      userInfo: null,
      typeInput1: "password",
      typeInput2: "password",
      password: '',
      confirmPassword:'',
      showPass1:false,
      showPass2:false,
    };
  },
  methods: {
    showPassAction1() {
      this.showPass1 = true;
      this.typeInput1 = "text";
    },
    hiddenPassAction1() {
      this.showPass1 = false;
      this.typeInput1 = "password";
    },
    showPassAction2() {
      this.showPass2 = true;
      this.typeInput2 = "text";
    },
    hiddenPassAction2() {
      this.showPass2 = false;
      this.typeInput2 = "password";
    },
    async updatePassword(){
        if(this.password.length<8){
            this.openAlert('Password phải tối thiểu 8 chữ cái.');
            return;
        }
        if(this.password !== this.confirmPassword){
            this.openAlert('Xác nhận mật khẩu không trùng khớp.');
            return;
        }

        try{
            let userItem = {
                password: this.password
            }
            await axios.patch(apiEndpoint+'api/AppUsers/'+this.userInfo.userId,userItem).then(res =>{
                this.openAlert('Bạn đã đổi mật khẩu thành công.');
                this.$f7router.navigate('/');
            })
        }catch(err){
            console.log(err);
        }
    },
     openAlert(titlename) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', () => {});
    },
    logout() {
        const app = this.$f7;
            localStorage.removeItem('eic_quiz_userInfo');
            localStorage.removeItem('eic_quiz_tokenKey');
            localStorage.removeItem('eic_quiz_dateExpireToken');
            this.$f7router.navigate('/');
    },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("eic_quiz_userInfo"));
  },
};
</script>