<style>
    .navbar{
        min-height: 40px;
    }
    .navbar-inner{
        max-width: 1250px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    @media (max-width: 680px) {
        .style-img-logo {
            width: 45% !important;
        }
    }
</style>

<template>
    <f7-navbar v-bind:style="styleObject">
        <f7-nav-left style="margin-left: 0px; max-width: 155px;">
            <f7-link @click="openConfirm()">
                <img
                    class="style-img-logo"
                    src="../assets/images/logo_eic.png"
                    alt=""/>
            </f7-link>    
        </f7-nav-left>
        
        <f7-nav-right style="margin-right: 0px; ">
            <span style="color: #E0613D;font-size:10pt;margin-right:2px" class="fs-roboto-light">{{userInfo.ten}}</span> 
  
            <f7-link popover-open=".popover-menu">
                <f7-icon ios="material:account_circle" md="material:account_circle" style="color: #E0613D"></f7-icon>
            </f7-link>    
        </f7-nav-right>
         <f7-popover class="popover-menu">
            <f7-list>
            <f7-list-item link="#" popover-close title="Bài thi của tôi" @click="gotoMyUserQuiz(userInfo.userId)"></f7-list-item>
            <f7-list-item link="#" popover-close title="Đổi mật khẩu" @click="gotoProfile(userInfo.userId)"></f7-list-item>
            <f7-list-item link="#" popover-close title="Đăng xuất" @click="logout()"></f7-list-item>
            </f7-list>
        </f7-popover>
    </f7-navbar>
</template>
<script>
    export default {
        name: 'NavBar',
        data() {
            return {
                styleObject:{
                    background: "#fff !important"
                },
                userInfo: {},
            }
        },
        methods:{
            gotoMyUserQuiz(userId){
                this.$f7router.navigate('/my-user-quiz/'+userId);
                console.log('viet userId',userId);
            },
            gotoProfile(userId){
                this.$f7router.navigate('/profile/'+userId);
            },
             openConfirm() {
                const app = this.$f7;
                if(this.$f7router.currentRoute.path === "/list-quiz/") return;
                if(this.$f7router.currentRoute.path.includes("/user-quiz/")){
                     app.dialog.confirm('<span class="fs-roboto-medium" style="color: #E7643B;font-size: 14px;">Bạn có muốn thoát?</span><br/><span class="fs-roboto-light" style="font-size: 14px;">Nhấn OK để xác nhận.</span>','', async() => {
                        this.$f7router.navigate("/list-quiz/");
                    });
                }else{
                    this.$f7router.navigate("/list-quiz/");

                }
               
            },
             logout() {
                const app = this.$f7;
                app.dialog.confirm('<span class="fs-roboto-medium" style="color: #E7643B;font-size: 14px;">Bạn có muốn đăng xuất?</span><br/><span class="fs-roboto-light" style="font-size: 14px;">Nhấn OK để đăng xuất tài khoản.</span>','', async() => {
                    localStorage.removeItem('eic_quiz_userInfo');
                    localStorage.removeItem('eic_quiz_tokenKey');
                    localStorage.removeItem('eic_quiz_dateExpireToken');
                    this.$f7router.navigate('/');
                });
            },
        },
        created(){
            this.userInfo =JSON.parse(localStorage.getItem('eic_quiz_userInfo'));
        }
    }
</script>
