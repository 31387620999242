<style>
  .style-content-page .page-content{
    padding-top: 0px !important;
  }
</style>
<style scoped>
.block{
  margin: 0;
  padding: 0px 15px;
  color: #8A8A8F;
}
</style>
<template>
<f7-page class="style-content-page">

    <f7-block class="no-padding no-margin">
        <div class="bg-dang-nhap" style="text-align: center">
                        <img src="./../assets/images/logo_eic.png" width="200px" style="margin-top:80px">
                    <!-- <div style="color: white;font-style: italic;">Version 1.0.0</div> -->

        </div>

        <f7-block class="display-flex align-items-center justify-content-center style-postion-block">
            <div class="list no-hairlines" style="width: 100%;">
                <div class="row">
                    <div class="col-100 medium-30" style="margin:auto" >
                        <ul style="border-radius: 5px;">
                            <li class="item-content item-input">
                                <!-- <div class="item-media align-self-center">
                                    <i class="fa fa-volume-control-phone" style="font-size: 20px" aria-hidden="true"></i>
                                </div> -->
                                <div class="item-inner no-margin-left">
                                    <div class="item-title item-label fs-roboto-medium">Tên tài khoản</div>
                                    <div class="item-input-wrap">
                                        <input type="text" :value="username" @input="username = $event.target.value" placeholder="Username">
                                        <span class="input-clear-button"></span>
                                    </div>
                                </div>
                            </li>
                            <li class="item-content item-input">
                                <!-- <div class="item-media">
                                    <i class="fa fa-key" style="font-size: 20px" aria-hidden="true"></i>
                                </div> -->
                                <div class="item-inner no-margin-left">
                                    <div class="item-title item-label fs-roboto-medium">Mật khẩu</div>
                                    <div class="item-input-wrap">
                                        <input :type="typeInput" :value="password" @input="password = $event.target.value" placeholder="Password">
                                        <!-- <span class="input-clear-button"></span> -->
                                        <i  v-if="!showPass" @click="showPassAction" class="fa fa-eye-slash" style="padding-right: 0px; position: absolute; top: 12px; right: 0px; color: #8C8C8C " aria-hidden="true"></i>
                                        <i  @click="hiddenPassAction"  v-if="showPass" class="fa fa-eye" style="padding-right: 0px; position: absolute; top: 12px; right: 0px; color: #8C8C8C" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <f7-button medium raised fill style="margin-top: 15px; height: 40px; line-height: 40px;background: darkred">
                            <f7-link class="fs-roboto-bold text-color-white" style="width: 100%;" @click="loginUser(username,password)">ĐĂNG NHẬP</f7-link>
                        </f7-button>
                        <br>
                        <div class="fs-roboto-light" style="color: #fff;text-align: center;width: 100%;font-size: 14px;">Version 1.0.5</div>
                    </div>
                </div>
            </div>


        </f7-block>
    </f7-block>
</f7-page>
</template>

<script>

import {Roles} from "./../../global.js"
import TrangThai from "./../../common.js"
import apiEndPoint from "./../../apiEndpoint.js";
import {
    EventLogin
} from "../event-login.js";
import axios from "axios";
export default {
    data() {
        return {
            username: "",
            password: "",
            userInfo: null,
            showPass: false,
            typeInput: "password"
        }
    },
    methods: {
         showPassAction(){
          this.showPass= true
          this.typeInput="text"
        },
        hiddenPassAction(){
          this.showPass= false
          this.typeInput="password"
        },
        async checkPhoneExist(phoneNumber) {
            let isPhoneExist = false;

            let url = apiEndPoint + "api/AppUsers/count";
            let res = await axios.get(url, {
                params: {
                    where: {
                        username: phoneNumber,

                    }
                }
            });
            let count = res.data.count;
            return count > 0 ? true : false;
        },
        openAlert(titlename) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Cảnh báo', () => {});
        },
        setAxiosGlobal(token) {
          axios.defaults.headers = { 'Authorization': token };
        },
        async loginUser(username, password) {
            if (!username || !password) {
                this.openAlert("Điền đầy đủ thông tin");
                return;
            } else {
                let url = apiEndPoint + 'api/AppUsers/login';
                let item = {
                    username,
                    password
                }
                try {
                    let res = await axios.post(url, item);
                    if (res.data.userId !== undefined && res.data.userId > 0) {
                        this.setAxiosGlobal(res.data.id);
                        await this.getDSPhongBan();
                        await axios.get(apiEndPoint + "api/AppUsers/" + res.data.userId).then(userData => {
                            if(!userData.data.isActive){
                               this.openAlert("Không thể đăng nhập!");
                              return ;
                            }else {
                               localStorage.setItem("eic_quiz_userInfo", JSON.stringify({
                                ten: userData.data.ten,
                                chucVu: userData.data.chucVu,
                                userId: userData.data.id,
                                userName: userData.data.username,
                                phongBanId: userData.data.phongBanId,
                                dsphongBanId: userData.data.phongBanQuanLyId,
                                roleId : userData.data.roleId,
                                isActive: userData.data.isActive || false,
                                email: userData.data.email
                              }))
                                this.$f7router.navigate("/list-quiz/");
                              localStorage.setItem("eic_quiz_tokenKey", res.data.id);
                              localStorage.setItem("eic_quiz_dateExpireToken",new Date(new Date().getTime()+ 7*24*60*60*1000).toISOString())
                              EventLogin.$emit("userLoggedIn", this.$userInfoGlobal);
                            }

                        })

                    }

                } catch (error) {
                    console.log(error);
                    this.openAlert("Không thể đăng nhập!");
                }
                return ;
            }

        },
    
      
         async getDSPhongBan() {
            let url = apiEndPoint + "api/PhongBans";
            let filter = {
                fields: {
                  "id":true,
                  "ten":true,
                  "ma":true,
                  "boPhan": true,
                },
                where: {

                }
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsPhongBan = res.data;
                localStorage.setItem("eic_quiz_dsPhongBan", JSON.stringify(this.dsPhongBan));

            } catch (error) {
                console.log(error);
            }
        },
       


    },
    async created() {
        let userData = JSON.parse(localStorage.getItem('eic_quiz_userInfo'));
        let dateExpireToken = new Date(localStorage.getItem('eic_quiz_dateExpireToken'));
        if(dateExpireToken && dateExpireToken<new Date()){
          return;
        }

        if(userData){
            setTimeout(()=> this.$f7router.navigate("/list-quiz/"),0)
        }


    }
};
</script>
