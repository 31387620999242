<style scoped>
    .bg {
        background:#fff !important;
    }
    .block{
        margin: 0;
        padding: 10px 0px;
        color: #8A8A8F;
    }
    .p-mt-10-i{
        margin-top: 10px !important;
    }
    .row > .col,
    .row > [class*="col-"] {
        padding: 0 5px;
    }
    .row .col-50 {
        width: 50% !important;
    }
    .style-height{
        padding: 9px;
    }
    .style-img-icon{
        height: 250px;
    }
    .show-576{
      display: none;
    }
    @media only screen and (min-width:1025px) {
        .row .desktop-25 {
            width: 25% !important;
        }
    }
    @media (max-width: 768px) {
        .row .tablet-33 {
            width: 33.333333333333336% !important;
        }
    }
    @media (max-width: 767px) {
        .row .col-33 {
            width: 33.333333333333336% !important;
        }
       
    }
    @media (max-width: 680px) {
        .row .col-33 {
            width: 50% !important;
        }
        /* .style-img-icon{
            height: 240px;
        } */
    }
    @media (max-width: 576px) {
        .show-576{
          display: block;
        }
        .hiden-576{
          display: none;
        }
    }
    @media (max-width: 425px) {
        .row .col-33 {
            width: 100% !important;
        }
    }

    .title-color{
        color: #D95E3E
    }

</style>

<template>
    <f7-page
        @page:beforein="pageBeforeIn"
        @page:afterin="pageAfterIn"
        :page-content="false"
        class="bg">
        <nav-bar></nav-bar>
        <f7-page-content>
                <f7-block-title><h2 class="fs-roboto-light" style="text-align: center;margin-top:20px;color:#D95E3E">BÀI THI CỦA TÔI</h2></f7-block-title>
                <f7-list media-list>
                <f7-list-item
                    class='fs-roboto-light title-color'
                    v-for="(item,index) in listMyUserQuiz"
                    :key="index"
                    :link="/result-detail-quiz/+item.id"
                    :badge="getBadgeStatus(item)" 
                    :badge-color="getColorBadge(item)"
                    :title="getQuizName(item.quizId)"
                    :text="formatDate(item.timeBeginQuiz)"
                ></f7-list-item>
                </f7-list>
        </f7-page-content>
    </f7-page>
</template>
<script>
    import axios from "axios";
    import apiEndpoint from '../../apiEndpoint';
    import NavBar from './NavBar.vue';
    import Vue from "vue";
    var dateFns = require("date-fns");
    export default {
        components : {
            NavBar
        },
        data() {
            return {
                apiUrl: apiEndpoint + "api/UserQuizzes",
                dataFromAPI: [],
                accessToken: "",
                listMyUserQuiz: [],
                timeCount: 0,
                interval: "",
                badgeStatus:"abc",
                colorBadge:"green",
                listQuiz: [],
            };
        },
        beforeRouteEnter(to, from, next) {
          // message takes a while to load
          next(vm => vm.text = 'Text obtained after request ');
        },
        methods : {
            getBadgeStatus(item){
                let tyLe = Math.floor(item.correctQuestions/item.listQuestions.length*100);
                let quiz = this.listQuiz.find(e => e.id === item.quizId);
                if(tyLe>=quiz.passGrade){
                    return 'PASS';
                }else{
                    return 'FAIL';
                }
            },
            getColorBadge(item){
                let tyLe = Math.floor(item.correctQuestions/item.listQuestions.length*100);
                let quiz = this.listQuiz.find(e => e.id === item.quizId);
                if(tyLe>=quiz.passGrade){
                    return 'green';
                }else{
                    return 'pink';
                }
            },
            getQuizName(quizId){
                let quiz = this.listQuiz.find(e=> e.id === quizId);
                if(!quiz) return '';
                return quiz.quizName;
            },
            formatDate(date){
                return dateFns.format(new Date(date),'dd/MM/yyyy HH:mm')
            },
            setAxiosGlobal(token) {
                axios.defaults.headers = { 'Authorization': token };
            },
            pageBeforeIn: function (e, page) {
                const app = this.$f7;
                app
                    .preloader
                    .show();
            },
            pageAfterIn: function (e, page) {
                const app = this.$f7;
                app
                    .preloader
                    .hide();
            },
            startTime() {
                this.interval = setInterval(function () {
                    this.timeCount++;
                }.bind(this), 1000);
            },
            stopTime() {
                clearInterval(this.interval);
            },
            setRating: function (rating, id) {
                this.rating = 5;
            },
            async getListMyUserQuiz(userId) {
                let filter = {where:{userId: userId, userquizStatus: 'COMPLETED'}, order: "id desc"}; // lọc danh sách bài trắc nghiệm
                try {
                    let data = await axios.get(this.apiUrl, {
                        params: {
                            filter: filter
                        }
                    });
                    this.dataFromAPI = data.data;
                } catch (error) {}
            },
            async getQuiz() {
                try {
                    let data = await axios.get( apiEndpoint + "api/Quizzes");
                    this.listQuiz = data.data;
                    console.log('this.listQuiz',this.listQuiz);
                } catch (error) {}
            },
        },
        async created() {
            let token = localStorage.getItem('eic_quiz_tokenKey');
            this.setAxiosGlobal(token);
            let userid = this.$f7route.params.userId;
            await this.getListMyUserQuiz(userid);
            await this.getQuiz();
            this.listMyUserQuiz = this.dataFromAPI;
            console.log('viet listMyUserQuiz',this.listMyUserQuiz);

        }
    };
</script>
