<style scoped>
    .bg {
        background:#fff !important;
    }
    .block{
        margin: 0;
        padding: 10px 0px;
        color: #8A8A8F;
    }
    h2{
        font-size: 25px;
        text-transform: uppercase;
    }
    .p-mt-10-i{
        margin-top: 10px !important;
    }
    .row > .col,
    .row > [class*="col-"] {
        padding: 0 5px;
    }
    .row .col-50 {
        width: 50% !important;
    }
    .style-height{
        padding: 9px;
    }
    .style-img-icon{
        height: 250px;
    }
    .show-576{
      display: none;
    }
    @media only screen and (min-width:1025px) {
        .row .desktop-25 {
            width: 25% !important;
        }
    }
    @media (max-width: 768px) {
        .row .tablet-33 {
            width: 33.333333333333336% !important;
        }
    }
    @media (max-width: 767px) {
        .row .col-33 {
            width: 33.333333333333336% !important;
        }
         h2{
            font-size: 22px;
            text-transform: uppercase;
        }
    }
    @media (max-width: 680px) {
        .row .col-33 {
            width: 50% !important;
        }
        /* .style-img-icon{
            height: 240px;
        } */
    }
    @media (max-width: 576px) {
        .show-576{
          display: block;
        }
        .hiden-576{
          display: none;
        }
    }
    @media (max-width: 425px) {
        .row .col-33 {
            width: 100% !important;
        }
    }

</style>

<template>
    <f7-page
        @page:beforein="pageBeforeIn"
        @page:afterin="pageAfterIn"
        :page-content="false"
        class="bg">
        <nav-bar></nav-bar>
        <f7-page-content class="">
            <f7-block class="main ps_what--container" style="padding: 0 10px !important; background-color: #fff;">
                <f7-block style="height: 100vh;">
                  
                    <f7-block>
                        <f7-row class="row-normal">
                            <f7-col
                                :key="index"
                                v-for="(item, index) in listQuiz"
                                :itemId="item.id"
                                desktop-width="33"
                                tablet-width="33"
                                width="33"
                                class="p-mb-15">
                                
                                <f7-card
                                    class="style-card bg-card elevation-8"
                                    style="background: #fff !important; border-top: 3px solid #E0613D !important">
                                      <f7-block-title class="text-center">
                                        <h2 class="fs-roboto-bold" style="color: #27515C !important;margin-top: 15px;">{{item.quizName}}</h2>
                                   </f7-block-title>
                                    <f7-card-header class="no-border demo-card-header-pic">
                                        <img class="style-img-icon" v-bind:src="item.quizImage" alt/>
                                    </f7-card-header>
                                    <f7-card-content class="style-height">
                                              <p
                                            class="limit-text row align-items-center justify-content-center fs-roboto-light"
                                            style="color: #073050 !important;"
                                            >{{item.quizDescription}}</p>
                                    </f7-card-content>
                                    <f7-block class="p-p-0-i">
                                        <f7-col width="100">
                                            <f7-button
                                                v-bind:href="'/user-quiz/'+ item.id + '/'+item.totalTime+'/'"
                                                fill-md
                                                fill-ios
                                                class="height-bt fs-roboto-medium"
                                                style="border-radius: 0; background-color: #E7643B; text-transform: none; font-size: 16px;">BẮT ĐẦU</f7-button>
                                        </f7-col>
                                    </f7-block>

                                </f7-card>
                            </f7-col>
                        </f7-row>
                    </f7-block>
                </f7-block>
            </f7-block>
        </f7-page-content>
    </f7-page>
</template>
<script>
    import axios from "axios";
    import apiEndpoint from '../../apiEndpoint';
    import NavBar from './NavBar.vue';
    import Vue from "vue";
    export default {
        components : {
            NavBar
        },
        data() {
            return {
                apiUrl: apiEndpoint + "api/Quizzes",
                dataFromAPI: [],
                accessToken: "",
                listQuiz: [],
                timeCount: 0,
                interval: ""
            };
        },
        beforeRouteEnter(to, from, next) {
          // message takes a while to load
          next(vm => vm.text = 'Text obtained after request ');
        },
        methods : {
            setAxiosGlobal(token) {
                axios.defaults.headers = { 'Authorization': token };
            },
            pageBeforeIn: function (e, page) {
                const app = this.$f7;
                app
                    .preloader
                    .hide();
            },
            pageAfterIn: function (e, page) {
                const app = this.$f7;
                app
                    .preloader
                    .hide();
            },
            startTime() {
                this.interval = setInterval(function () {
                    this.timeCount++;
                }.bind(this), 1000);
            },
            stopTime() {
                clearInterval(this.interval);
            },
            setRating: function (rating, id) {
                this.rating = 5;
            },
            async getListQuiz() {
                let filter = {where:{quizStatus: "ACTIVE"}}; // lọc danh sách bài trắc nghiệm
                try {
                    let data = await axios.get(this.apiUrl, {
                        params: {
                            filter: filter
                        }
                    });
                    this.dataFromAPI = data.data;
                } catch (error) {}
            },
        },
        async created() {
            let token = localStorage.getItem('eic_quiz_tokenKey');
            this.setAxiosGlobal(token);
            await this.getListQuiz();
            this.listQuiz = this.dataFromAPI;

        }
    };
</script>
